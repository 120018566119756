<template>
    <b-card>
        <b-card-header>
            <b-row class="col-4">
                <b-col>
                    <p>Approval Time</p>
                    <v-select
                        label="name"
                        :options="approvalOptions"
                        @option:selected="optionChosen"
                    />
                </b-col>
            </b-row>
        </b-card-header>
        <b-card-body>
        <BarChart
            :labels="labels"
            :data="chartData"
        />
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
} from 'bootstrap-vue'
import VSelect from "vue-select"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import BarChart from './BarChart.vue'
export default {
    components: {
        BarChart,
        VSelect,
        BCard,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
    },
    data() {
        return {
            labels: [],
            chartData: [],
            approvalDate: 7,
            approvalOptions: [
                { name: '7 Days', date: 7 },
                { name: '1 Month', date: 30 },
                { name: 'All', date: 'all' },
            ],
        };
    },
    created() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            this.$axios.post('/jobs/get_job_time_chart', {
                day: this.approvalDate,
            })
                .then(res => {
                    if (res.data) {
                        this.labels = res.data.map(itm => itm.branch_name)
                        this.chartData = res.data.map(itm => itm.time_gap_hours)
                    }
                })
                .catch(error => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                    console.error(error);
                });
        },
        optionChosen(val) {
            this.approvalDate = val.date
            this.fetchData()
        },
    },
}
</script>
