<template>
<div>
<canvas
id="myChart"
ref="myChart"
width="400"
height="150"
/>
</div>
</template>

<script>
import Chart from 'chart.js';
export default {
  props: {
    labels: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      nlabels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
      myChart: '',
    };
  },
  watch: {
    labels(val) {
      this.myChart.config.data.labels = val
      this.myChart.update()
      console.log('child watch', val, this.myChart, this.myChart.config.data.labels)
    },
    data(val) {
      this.myChart.config.data.datasets[0].data = val
      this.myChart.update()
      console.log('child watch data', val, this.myChart.config.data.datasets[0].data)
    },
  },
  mounted() {
    console.log('mount', this.labels)
    // const ctx = document.getElementById('myChart').getContext('2d');
    const ctx = this.$refs.myChart.getContext('2d')
    this.myChart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: this.labels,
            datasets: [{
                label: '# Average Approval Time (hrs)',
                data: this.data,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            }],
        },
        options: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    },
                }],
            },
        },
    });
  },
}
</script>
